import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/Humanity/2024/Randezvous/1.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/2024/Randezvous/2.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/2024/Randezvous/3.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/2024/Randezvous/4.jpg';
// import p5 from 'assests/Photos/Clubs/Humanity/2024/Randezvous/5.jpg';
// import p6 from 'assests/Photos/Clubs/Humanity/2024/Randezvous/6.jpg';
// import p7 from 'assests/Photos/Clubs/Humanity/2024/Randezvous/7.jpg';


// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';
import Sidebar2024 from '../Humanity2023/Sidebar2024';


const Rendezvous2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/Humanity/Randezvous/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/Humanity/Randezvous/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/Humanity/Randezvous/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/Humanity/Randezvous/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/Humanity/Randezvous/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/Humanity/Randezvous/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/association/Humanity/Randezvous/7.webp`;



  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1.3,
        cols: 2,
      },
      
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h5' align='center' >
      RENDEZVOUS AND EXPERT INSIGHT 
                                </Typography>
                                <br></br>
                                {/* <Typography variant='h7' align='justify' >
                                Class 4: Diversity Montage
                                Class 5: Continental Montage 

                                </Typography> */}
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 10, 11 & 12  &  Date: 12 August 2024
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Dependence on nature will lead to harmony and peace in society and will not cause depravity of individuals’”-Rousseau

<br/>
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        On 12 August 2024, National Public School, Yeshwanthpur, organized a Rendezvous; an interschool quiz competition for students of Grade 10-12 cantered on the themes of Law, Democracy, Media, and U. It was an enlightening event, showcasing the intellectual engagement and analytical skills of high school students from various schools. The competition not only tested participants' knowledge on these crucial subjects but also highlighted the importance of understanding their roles in shaping society. The event fostered a deeper understanding of how Law and Media intersect with Democracy, underscoring the importance of informed Citizenship.   
        <br></br>
        Following the quiz competition, the session titled "Expert Insight" was led by Timothy Franklyn, Founder, National School of Journalism and Public Discourse, Bengaluru. His discussion provided valuable guidance on pursuing careers in Law and Journalism and the broader impact of such careers on personal and societal levels. The interschool quiz competition and the subsequent Expert Insight session was highly informative and inspirational. They provided students with a comprehensive understanding of the intersections between Law, Democracy and Media while offering practical guidance for future career paths. The emphasis on personal passion and societal contribution resonated deeply, leaving participants motivated to explore their roles in shaping the future. 
        <br></br>
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Winning a case isn’t just about knowing the law- it is about mastering the art of persuasion and making your voice

       impossible to ignore.”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Rendezvous2024;